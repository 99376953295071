import React from 'react';
import Modal from '../../../components/Modal';
import Quote from '../../../components/Quote';

const media = {
  secTitle: 'Setting a Permissive Tone for Racism',
  type: 'video',
  video: {
    src: 'https://www.youtube.com/embed/QNiMIOlYv04',
  },
};

export default () => (
  <Modal closeTo="Learned" media={media}>
    <p className="Title--2 Title--c">
      Political leadership and media have power and some are setting a
      permissive tone for racist behavior and reinforce stereotypes.
    </p>
    <p>
      While citizens of the American South acknowledge that racial divisions in
      their communities are not new, they shared that they have experienced a
      recent growth in the outward expression of racial animosity as a result of
      the national political climate. This viewpoint is in alignment with a 2017
      Pew survey in which 60 percent of Americans say President Donald Trump’s
      election has led to worse race relations in the U.S. Most of the increase
      in negative opinions has been among Republicans and Republican-leaning
      Independents.
    </p>
    <p>
      Another viewpoint shared was that the election of President Obama caused
      some white people to fear the loss of their position of power in society.
      Some respondents drew a direct line between the election of the nation’s
      first black president and the election of a man who they characterize as
      emboldening and encouraging the racist behavior of those who feel
      threatened by perceived shifts in racial power dynamics.
    </p>
    <p>
      The media was also cited as a major driving force for perpetuating
      stereotypes. The media tends to distort perceptions of black men. News and
      opinion media over-represent the proportion of black families receiving
      welfare by 18 percentage points. That is, black families represent 60
      percent of welfare recipients in news and opinion media but make up just
      42 percent of welfare recipients, according to official government
      reports. News and opinion media are also 1.32 times more likely to
      associate black family members with criminality compared to white family
      members.
    </p>
    <p>
      These false and negative representations lead to tangible consequences for
      people of color. Damaging perceptions of violence contribute to a desire
      to isolate, wall off, or “contain” the communities that are understood to
      be problematic — particularly black communities. So it’s easy to see why
      politics and media were ultimately mentioned as things that divide
      communities by race and class and negatively impact state and local power
      dynamics.
    </p>
    <Quote
      quote="It has not surfaced in the past until Trump was elected. And I think his election has emboldened the racists—people with a racist attitude, economically, educationally otherwise—to become more pronounced, more aggressive."
      person="Black male, 78"
      title="Richmond"
    />
    <Quote
      quote="The man in the White House is driving it. It’s everywhere you go. I’ve never seen it like this, and it makes me sick to my stomach...This is home, and I’ve been to lots of places, but people just feel like they can do whatever they wanna do to you, and you should take it."
      person="Black female, 66"
      title="Charleston, WV"
    />
    <Quote
      quote="I feel like the white community feels like this country...in the past, felt like this country was just theirs. And they began to be threatened when black people started coming up. Like, we’ve had a black president. That empowered us, and it seems like now white people are scared that, ‘Oh, my God, black people are starting to come up now. You know, everything’s black... Black people are taking over.’"
      person="Black male"
      title="Montgomery"
    />
    <Quote
      quote="After first black administration took office here—the first black mayor and chief of police—the smear job came. So any little thing that happens here, it’ll get the news. But the same thing and more will happen down the road and other surrounding towns too, and there’s nothing...So we have a stigma of violence, and crime, you gonna get killed. But anybody who come riding through here right now today, and nothing would ever happen. The media did that."
      person="Black male"
      title="Rural Louisiana"
    />
    <Quote
      quote="When you go to, like, the government housing areas, they don’t have heat or something, you know. I’ve seen on the news. I won’t even go to that area, you know? It’s basically all Black people, you know, gangs and stuff."
      person="White male"
      title="Richmond"
    />
  </Modal>
);
